import React, { Ref, useImperativeHandle } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  MuiEvent,
  DataGridPro,
  useGridApiRef,
  GridColDef,
  DataGridProProps,
} from "@mui/x-data-grid-pro";
import { Typography, Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { theme } from "@theme";

interface DataTableProProps<T> extends DataGridProProps {
  columns: GridColDef[];
  rows: T[];
  updateRow: (row: GridRowModel) => boolean;
  readonly?: boolean;
  emptyRowComment?: string;
}

export interface DataTableProRefObject {
  trigger: () => boolean;
}

export const DataTablePro = React.forwardRef(
  <T extends Partial<T>>(
    props: DataTableProProps<T>,
    ref: Ref<DataTableProRefObject>
  ) => {
    const { updateRow } = props;
    const apiRef = useGridApiRef();
    const [cellModesModel, setCellModesModel] =
      React.useState<GridCellModesModel>({});

    useImperativeHandle(ref, () => ({
      trigger: (): boolean => {
        // check if there are any rows in edit mode
        return Object.keys(apiRef.current.state.editRows).length === 0;
      },
    }));

    // A generic handle for cell click. This method is called on each cell click which unsets the edit mode of all the cells/Rows
    // previously in edit mode and sets the edit mode of the clicked cell.
    const handleCellClick = React.useCallback(
      (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
        // Ignore if the cell is not editable
        if (!params.isEditable) {
          return;
        }

        // Ignore portal
        if (!event.currentTarget.contains(event.target as Element)) {
          return;
        }

        setCellModesModel((prevModel) => {
          return {
            // Revert the mode of the other cells from other rows
            ...Object.keys(prevModel).reduce(
              (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                  (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {}
                ),
              }),
              {}
            ),
            [params.id]: {
              // Revert the mode of other cells in the same row
              ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({
                  ...acc,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
              [params.field]: { mode: GridCellModes.Edit },
            },
          };
        });
      },
      []
    );

    // updates the cellModesModel state when the cell mode changes
    const handleCellModesModelChange = React.useCallback(
      (newModel: GridCellModesModel) => {
        setCellModesModel(newModel);
      },
      []
    );

    // updates the row when the row is updated
    const processRowUpdate = React.useCallback(
      (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        updateRow(newRow);
        return updatedRow;
      },
      [updateRow]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
      console.error(error);
    }, []);

    return (
      <>
        <ClickAwayListener
          disableReactTree={true}
          onClickAway={(event) => {
            (event.target as HTMLBaseElement)?.tagName !== "LI" &&
              apiRef.current?.setRowSelectionModel([]);
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            {...props}
            autoHeight
            hideFooterPagination={true}
            hideFooter={true}
            getDetailPanelHeight={() => "auto"}
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            cellModesModel={cellModesModel}
            isCellEditable={() => !props.readonly}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            onCellDoubleClick={(_params, event) => {
              event.defaultMuiPrevented = true;
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            disableVirtualization={true}
            getRowHeight={() => "auto"}
            slots={{
              noRowsOverlay: () => (
                <div
                  className="empty-datagrid-message"
                  style={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body2">
                    {props.emptyRowComment || "No data"}
                  </Typography>
                </div>
              ),
              detailPanelExpandIcon: () => (
                <Tooltip title="Target Objectives">
                  <ChevronRightIcon></ChevronRightIcon>
                </Tooltip>
              ),
              detailPanelCollapseIcon: () => (
                <Tooltip title="Target Objectives">
                  <ExpandMoreIcon></ExpandMoreIcon>
                </Tooltip>
              ),
            }}
            // Change this to use theme
            sx={{
              border: 0,
              "& .MuiDataGrid-columnHeader": {
                padding: "1px",
              },
              "& .MuiDataGrid-row": {},
              "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                padding: "0px",
              },
              "& .MuiDataGrid-cell": {
                padding: "0",
                border: 0,
                borderBottom: `1px solid ${theme.palette.borderColor.main}`,
                borderBottomColor: `${theme.palette.borderColor.main} !important`,
                display: "flex",
              },
              "& .MuiDataGrid-columnHeaderDraggableContainer": {
                padding: "8px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 600,
                whiteSpace: "normal",
                lineHeight: "24px",
                textOverflow: "inherit",
              },
              "& .Mui-error": {
                backgroundColor: theme.datagrid.cell.validationError,
                border: "1px solid " + theme.palette.error.main,
                borderBottomColor: `${theme.palette.error.main} !important`,
              },
              "& .MuiDataGrid-detailPanelToggleCell": {
                marginLeft: "8px",
              },
              "& .MuiDataGrid-virtualScroller:has(.empty-datagrid-message)": {
                height: "68px",
              },

              "& .MuiDataGrid-overlayWrapperInner:has(.empty-datagrid-message)":
                {
                  height: "68px!important",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                },
            }}
          />
        </ClickAwayListener>
      </>
    );
  }
);
