import AddIcon from "@mui/icons-material/Add";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowParams,
  GridValueSetter,
} from "@mui/x-data-grid";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
} from "@mui/material";
import { DosePrescription, SiteTableProps } from "./SiteTable.types";
import { CustomCellAutoComplete } from "@components/DataTable/CustomCellAutocomplete";
import { DataTablePro } from "@components/DataTable/DataTablePro";
import { DoseMenuEditField } from "@components/DataTable/DoseCellEditField";
import { DoseViewField } from "@components/DataTable/DoseCellViewField";
import { Ref, useImperativeHandle } from "react";
import TargetObjectiveTableApi, {
  TargetObjectiveTableApiRefObject,
} from "@components/TargetObjectiveTable/TargetObjectiveTableApi";
import { useCallback, useState } from "react";
import React from "react";
import { DataTableRefObject } from "@components/DataTable/DataTable";
import { GridRowId } from "@mui/x-data-grid-pro";
import { CustomCellNumberField } from "@components/DataTable/CustomCellNumberField";
import SiteModal from "@pages/Order/components/SiteNameModal/SiteNameModal";
import { SiteEditField } from "@components/DataTable/SiteEditField";
import { CustomCellDisplay } from "@components/DataTable/CustomCellDisplay";
import { getDoses, getDoseFractions } from "@utils/doseAndFractions";

export interface SiteTableRefObject {
  trigger: () => boolean;
}

const doseNames = [
  [""],
  ["highDose"],
  ["highDose", "lowDose"],
  ["highDose", "mid1Dose", "lowDose"],
  ["highDose", "mid1Dose", "mid2Dose", "lowDose"],
];

const SiteTable = React.forwardRef(
  (props: SiteTableProps, ref: Ref<SiteTableRefObject>) => {
    const [showAddSite, setShowAddSite] = useState<boolean>(false);
    const tableRef = React.useRef<DataTableRefObject>(null);
    const targetObjectiveTableApiRefs = React.useRef<
      (TargetObjectiveTableApiRefObject | null)[]
    >([]);
    const isEditable = !props.isReadOnly;
    const doseToArray = (row: DosePrescription) => {
      const dose = [row.highDose, row.mid1Dose, row.mid2Dose, row.lowDose];
      return dose.filter((doseValue) => doseValue !== null);
    };

    const treatmentSiteColumn: GridColDef = {
      field: "name",
      headerName: "Treatment Site",
      minWidth: 120,
      flex: 1,
      editable: isEditable,
      sortable: false,
      type: "string",
      valueGetter: (_value, row): (string | null | undefined)[] => {
        return [row.name, row.nameSpecify];
      },
      valueSetter: (value, row): GridValueSetter => {
        return {
          ...row,
          name: value[0],
          nameSpecify: value[1],
        };
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SiteEditField
            options={props.treatmentSiteOptions}
            updateSite={props.updateSite}
            {...params}
          />
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomCellDisplay
            {...params}
            value={`${params.value[0]} ${params.value[1] || ""}`.trim()}
            readOnly={!isEditable}
          />
        );
      },
    };

    const lateralityColumn: GridColDef = {
      field: "laterality",
      headerName: "Laterality",
      editable: isEditable,
      sortable: false,
      minWidth: 100,
      flex: 1,
      type: "singleSelect",
      valueOptions: props.lateralityOptions,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellAutoComplete dataTestId="laterality-edit" {...params} />
      ),

      renderCell: (params: GridRenderCellParams) => (
        <CustomCellAutoComplete
          dataTestId="laterality-view"
          {...params}
          readOnly={!isEditable}
        />
      ),
    };
    const techniqueColumn: GridColDef = {
      field: "technique",
      headerName: "Technique",
      editable: isEditable,
      sortable: false,
      minWidth: 100,
      flex: 1,
      type: "singleSelect",
      valueOptions: props.techniqueOptions,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellAutoComplete dataTestId="technique-edit" {...params} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <CustomCellAutoComplete
          dataTestId="technique-view"
          {...params}
          readOnly={!isEditable}
        />
      ),
    };
    const sequenceColumn: GridColDef = {
      field: "sequence",
      headerName: "Sequence",
      editable: isEditable,
      minWidth: 100,
      flex: 1,
      sortable: false,
      type: "singleSelect",
      valueOptions: props.sequenceOptions,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellAutoComplete dataTestId="sequence-edit" {...params} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <CustomCellAutoComplete
          dataTestId="sequence-view"
          {...params}
          readOnly={!isEditable}
        />
      ),
    };
    const isoDoseColumn: GridColDef = {
      field: "isodose",
      headerName: "Prescription PT/Isodose",
      editable: isEditable,
      minWidth: 100,
      flex: 1,
      sortable: false,
      type: "singleSelect",
      valueOptions: props.isodoseOptions,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellAutoComplete dataTestId="isodose-edit" {...params} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <CustomCellAutoComplete
          dataTestId="isodose-view"
          {...params}
          readOnly={!isEditable}
        />
      ),
    };
    const doseColumn: GridColDef = {
      field: "dose",
      headerName: `Dose (${props.doseUnit})`,
      editable: isEditable,
      minWidth: 100,
      flex: 1,
      sortable: false,
      type: "string",
      valueGetter: (_value, row) => {
        const doses = getDoses(row);
        return { doses, openDialog: row.openDialog };
      },
      valueSetter: (value, row): GridValueSetter => {
        // update the dose values from array to individual values
        const modifiedDoses: Map<string, number | null> = value.doses.reduce(
          (
            updatedDoses: Map<string, number | null>,
            doseValue: number | null,
            index: number
          ) => {
            updatedDoses.set(doseNames[value.doses.length][index], doseValue);
            return updatedDoses;
          },
          new Map<string, number | null>()
        );
        const updatedRow = {
          ...row,
          highDose: null,
          lowDose: null,
          mid1Dose: null,
          mid2Dose: null,
          ...Object.fromEntries(modifiedDoses),
        };
        return updatedRow;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DoseMenuEditField
          {...params}
          readOnly={false}
          units={props.doseUnit}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <DoseViewField {...params} readOnly={!isEditable} />
      ),
    };
    const fractionsColumn: GridColDef = {
      field: "fractions",
      headerName: `Fraction (${props.fractionUnit})`,
      editable: isEditable,
      minWidth: 100,
      flex: 1,
      sortable: false,
      type: "string",
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellNumberField {...params} numberType="integer" />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <CustomCellNumberField
          {...params}
          numberType="integer"
          readOnly={!isEditable}
        />
      ),
    };
    const doseFractionColumn: GridColDef = {
      field: "doseFraction",
      headerName: `Dose (${props.doseUnit}/${props.fractionUnit})`,
      minWidth: 100,
      flex: 1,
      valueGetter: (_value, row) => {
        if (!row.fractions) return null;
        const doses = getDoses(row);
        const doseFractions = getDoseFractions(row.fractions, doses);

        return { doses: doseFractions, openDialog: false };
      },
      editable: false,
      sortable: false,
      type: "string",
      renderCell: (params: GridRenderCellParams) => (
        <DoseViewField {...params} readOnly />
      ),
    };
    const frequenceColumn: GridColDef = {
      field: "frequency",
      headerName: "Frequency",
      editable: isEditable,
      minWidth: 100,
      flex: 1,
      type: "singleSelect",
      valueOptions: props.frequencyOptions,
      sortable: false,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellAutoComplete dataTestId="frequency-edit" {...params} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <CustomCellAutoComplete
          dataTestId="frequency-view"
          {...params}
          readOnly={!isEditable}
        />
      ),
    };
    const energyColumn: GridColDef = {
      field: "energy",
      headerName: "Energy",
      editable: isEditable,
      minWidth: 100,
      flex: 1,
      type: "singleSelect",
      valueOptions: props.energyOptions,
      sortable: false,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCellAutoComplete dataTestId="energy-edit" {...params} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <CustomCellAutoComplete
          dataTestId="energy-view"
          {...params}
          readOnly={!isEditable}
        />
      ),
    };
    const actionsColumn: GridColDef = {
      field: "actions",
      type: "actions",
      flex: 0.1,
      getActions: (params: GridRowParams) =>
        isEditable
          ? [
              <Tooltip title="Remove site" placement="bottom">
                <GridActionsCellItem
                  {...params}
                  icon={<RemoveCircle />}
                  onClick={() => isEditable && props.removeSite(params.id)}
                  label="Delete"
                  id="delete"
                />
              </Tooltip>,
            ]
          : [],
    };
    const columns: GridColDef[] = [
      treatmentSiteColumn,
      lateralityColumn,
      techniqueColumn,
      energyColumn,
      doseColumn,
      fractionsColumn,
      doseFractionColumn,
      isoDoseColumn,
      sequenceColumn,
      frequenceColumn,
      actionsColumn,
    ];
    const hasErrors = props.dosePrescriptions.some(
      (row) =>
        row.errors &&
        row.errors.length > 0 &&
        row.touched &&
        row.touched.some((field) => row.errors && row.errors.includes(field))
    );
    useImperativeHandle(ref, () => ({
      trigger: (): boolean => {
        // check if target objective is in sync
        const isTargetObjectiveInSync =
          targetObjectiveTableApiRefs.current.every((ref) => {
            if (ref) {
              return ref.trigger();
            }
            return true;
          }) || false;
        return (
          (tableRef.current?.trigger() && isTargetObjectiveInSync) || false
        );
      },
    }));

    // MUI says to use a callback for optimisation so i just do what im told https://mui.com/x/react-data-grid/master-detail/#:~:text=Always%20memoize%20the%20function
    const expandablePanel = useCallback(
      (id: GridRowId, row: DosePrescription) => (
        <TargetObjectiveTableApi
          isReadOnly={props.isReadOnly}
          siteId={Number(row?.id)}
          siteName={row?.name || "Site"}
          doseLength={doseToArray(row)?.length}
          ref={(ref) => {
            const rowId = parseInt(id.toString()) || 0;
            targetObjectiveTableApiRefs.current[rowId] = ref;
          }}
        />
      ),
      [props.isReadOnly]
    );
    return (
      <Card
        data-testid={props.dataTestid}
        style={{ marginLeft: "2px", marginRight: "2px" }}
      >
        <CardHeader
          title="Dose Prescription"
          titleTypographyProps={{ variant: "body1" }}
          subheader="Click the expand button for target objectives"
          subheaderTypographyProps={{ variant: "body2" }}
        />
        {hasErrors && <Alert severity="error">Missing required fields</Alert>}
        <CardContent
          style={{
            paddingTop: 0,
            flexDirection: "column",
            gap: "16px",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Stack overflow="auto" width={1}>
            <DataTablePro
              ref={tableRef}
              rows={props.dosePrescriptions}
              columns={columns}
              updateRow={props.updateSite}
              readonly={!isEditable}
              emptyRowComment="Treatment sites will be displayed here once added"
              getDetailPanelContent={({ id, row }: GridRowParams) => {
                return expandablePanel(id, row as DosePrescription);
              }}
            />
          </Stack>
          {isEditable && (
            <Button
              disabled={!isEditable}
              onClick={() => {
                setShowAddSite(true);
              }}
              variant="outlined"
            >
              <AddIcon fontSize="small" color="primary" />
              Add Treatment Site
            </Button>
          )}
        </CardContent>
        <SiteModal
          open={showAddSite}
          options={props.treatmentSiteOptions}
          onSubmit={async (name, nameSpecify) => {
            await props.addSite(name, nameSpecify);
            setShowAddSite(false);
          }}
          onDismiss={() => {
            setShowAddSite(false);
          }}
        />
      </Card>
    );
  }
);

export default SiteTable;
